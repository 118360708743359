import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { showErrorToUser } from '@/utils/request/showErrorToUser';

export const onResponseRejected = async (error: any) => {
  const statusCode = error.response?.status;
  const authStore = useAuthStore();

  if (statusCode === 401) {
    authStore.setIsAuthorized(false);
    return router.push({ name: 'login' });
  }

  // If user tries to login with existing account that has no organization (django admin account for example)
  if (statusCode === 403) router.push({ name: 'root' });

  // Pass 4XX errors to toast
  if (`${error.response?.status}`[0] === '4') showErrorToUser(error);

  return Promise.reject(error);
};
